.App {
  text-align: center;
 
  min-height: 100vh;

}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



.justify-content-start{
  text-align:left;
}

.pad-10{
  padding: 10%
}

.pad-5{
  padding: 5%;
}

.pad-x-10{
  padding-left: 10%;
  padding-right: 10%;
}

.pad-x-5{
  padding-left: 5%;
  padding-right: 5%;
}

.margin-x-10{
  margin-left: 10%;
  margin-right: 10%;
}

.margin-y-2{
  margin-top: 2%;
  margin-bottom: 2%;
}


.mt-0{
  margin-top: 0;
}

.mt-2{
  margin-top: 2%;
}

.margin-0{
  margin: 0;
}

.pad-y-2{
  padding-top: 2%;
  padding-bottom: 2%;
}

.pad-2{
    padding: 2%;
}

.border-white1{
  border: 1px solid ;
}

.border-white-rounded{
  border: 1px solid;
  border-radius: 5px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}